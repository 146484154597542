import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import Image from "components/Image";
import AppleIcon from "components/AppleIcon";
import background from "../../../images/background/prototype_bg.jpg";
import "./Prototype.scss";

const Prototype = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, imageFileName, mobileImageFileName, appleIcon, spacelensLogo } = frontmatter;

  return (
    <PageSection className={clsx("prototype-section pb-5", "bg-dark", className)} id={anchor} style={{ backgroundImage: `url(${background})` }}>
      <Row className="justify-content-md-center">
        <Col lg={11}>
          <Image className="image mb-5 prototype-desktop-image" fileName={imageFileName} alt={rootHeader} />
          <Image className="image mb-5 d-md-none prototype-mobile-image" fileName={mobileImageFileName} alt={rootHeader} />
          <AppleIcon imageFileName={appleIcon} className="apple-icon my-5 mx-auto" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="text-center mt-md-5 text-light">Your business, your life, <span className="no-break">your <span className="primary-color">data</span></span></h2>
          <Image className="spacelens-logo mt-5 mx-auto" fileName={spacelensLogo} />
        </Col> 
      </Row>
    </PageSection>
  );
};

Prototype.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Prototype.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Prototype;
