import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import TeamMember from "components/TeamMember";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import background from "../../../images/background/team_bg.jpg";
import "./Team.scss";

const Team = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    teamMember,
  } = frontmatter;

  return (
    <PageSection className="bg-dark team-section py-3 py-md-5" id={anchor} containerClassName="container-large" style={{ backgroundImage: `url(${background})` }}>
      <Row>
        <SectionHeader header={rootHeader} />
      </Row>
      <Row>
        {teamMember.map(({ header, ...tmProps }) => (
          <Col sm={6} md={4} lg={3} key={header}>
            <TeamMember header={header} {...tmProps} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Team.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Team.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Team;
