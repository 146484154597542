import React from "react";
import PropTypes from "prop-types";
import Image from "components/Image";
import { Container, Row, Col } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";
import "./Footer.scss";

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    copyright,
    footerLogoFileName,
    navigationLinks,
    footerAddress,
    phoneIcon,
    chatIcon,
  } = frontmatter;

  return (
    <footer className="footer py-4 text-light" style={{ background: "rgb(1,1,1)" }}>
      <Container fluid>
        <Row className="footer-row">
          <Col lg={5} md={8} sm={12} xs={12} className="text-lg-left">
            <Image className="footer-logo mb-4" fileName={footerLogoFileName} />
            <ul className="footer-links">
              {navigationLinks.map(({ title, link }) => (
                <li key={title} className="footer-link">
                  <a href={link}>{title}</a>
                </li>
              ))}
            </ul>
          </Col>
          <Col md={3} sm={7} xs={8} className="text-md-right">
            {copyright}
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
