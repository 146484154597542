import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import Image from "components/Image";
import clsx from "clsx";
import background from "../../../images/background/introduction-bg.jpg";
import "./Introduction.scss";

const Introduction = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, services1, services2, imageFileName, arrowImageFileName } = frontmatter;

  return (
    <PageSection className={clsx("py-md-5 py-sm-4 py-xs-4 introduction-section", className)} id={anchor} style={{ backgroundImage: `url(${background})` }}>
      <Row>
        <div className="section-header text-center  col-lg-12 mb-4 mb-md-5">
          <h4 className="mb-3 mb-md-4 section-subheading">Shopping reinvented</h4>
          <h2 className="section-heading">The <span className="primary-color">SPACE</span> token</h2>
          <h4>Supercharge your eCommerce experience by earning and using your $SPACE token</h4> 
        </div>
      </Row>
      <Row className="text-center justify-content-md-center align-items-md-center text-sm-left">
        <Col lg={3} md={{order: 1, span: 4}} sm={{order: 2, span: 12}} xs={{order: 2, span: 12}}>
        {services1.map((service, index) => (
          
            <ServiceItem key={rootHeader} {...service} className="service-item"/>
          
        ))}
        </Col>
        <Col md={{order: 2, span: 3}} sm={{order: 1, span: 12}} xs={{order: 1, span: 12}}>
          <Image className="mr-md-2 ml-md-2 mx-sm-auto mx-xs-auto introduction-main-image" fileName={imageFileName} />
        </Col>
        <Col lg={3} md={{order: 3, span: 4}} sm={{order: 3, span: 12}} xs={{order: 3, span: 12}}>
          {services2.map((service, index) => (
              <ServiceItem key={rootHeader} {...service} className="service-item" inverted="true" />
          ))}
        </Col>
      </Row>
      <Row>
        <div className="section-header text-center col-lg-12 mt-3 mt-sm-3 mt-lg-5 mt-md-5">
          <h2 className="introduction-footer-text">Join Spacelens. <span className="primary-color">For buyers &amp; sellers.</span></h2>
        </div>

        <div className="downward-arrow-container mt-4">
          <Image className="mt-5 downward-arrow-image" fileName={arrowImageFileName} />
        </div>
 
      </Row>
    </PageSection>
  );
};

Introduction.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Introduction.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Introduction;
