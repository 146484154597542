import React from "react";
import PropTypes from "prop-types";

import { Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import Image from "components/Image";
import PDF from "../images/pdf/space-coin-whitepaper-v14.pdf";

import "./NavItem.scss";

const NavItem = ({ to, onClick, children, title, icon }) => {
  return (
    <Nav.Item>
      {title === "Whitepaper" ? (
        <a className="nav-link cursor-pointer" href={PDF}>
          <span>{children || title}</span>
        </a>
      ) : (
        <a className="nav-link cursor-pointer" href={to}>
          {icon && <Image alt="" fileName={icon} className="nav-link-custom-icon" />}
          <span>{children || title}</span>
        </a>
      )}
    </Nav.Item>
  );
};

NavItem.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  icon: PropTypes.string,
};

NavItem.defaultProps = {
  to: "",
  title: "",
  onClick: null,
  children: null,
  icon: null,
};

export default NavItem;
