import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import Client from "components/Client";

const SectionHeader = ({ header, className, ...restProps }) => {

  return (
    <Col lg={12} className={clsx("section-header", "text-center", className)} {...restProps}>
      <h2 className="section-heading">Past <span className="primary-color">Experiences</span></h2>
    </Col>
  );
};

const Experiences = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, clients, heading } = frontmatter;

  return (
    <PageSection className={clsx("py-5", className)} id={anchor}>
      <Row>
        <SectionHeader header={heading} className="mb-md-5 mb-3" />
      </Row>
      <Row md={6} sm={2} xs={2}>
        {clients.map(({ href, imageFileName }) => (
          <Col className="my-3" key={imageFileName}>
            <Client href={href} imageFileName={imageFileName} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Experiences.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};



Experiences.defaultProps = {
  className: null,
  frontmatter: null,
};

SectionHeader.propTypes = {
  className: PropTypes.string,
  header: PropTypes.object,
};

SectionHeader.defaultProps = {
  className: null,
  header: null,
};

export default Experiences;
