import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Image from "components/Image";

import background from "../../../images/background/token_bg.jpg";
import "./Token.scss";

const Token = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    imageFileName,
    tokenSupply,
    tokenTicker,
    tokenChartLegend,
    rootContent
  } = frontmatter;

  return (
    <PageSection className={clsx("bg-dark", "token-section","py-5" )} id={anchor} containerClassName="container-large" style={{ backgroundImage: `url(${background})` }}>
      <Row>
        <SectionHeader header={rootHeader} className="mb-4 mb-md-0" />
      </Row>
      <Row className="token-content-container">
        <Col lg={4}>
          <Image className="image token-main-image" fileName={imageFileName} alt={rootHeader} />
        </Col>
        <Col lg={4} className="chart-legend-col">
          <ul className="chart-legend-list">
            {tokenChartLegend.map(({ title, background:linearBg }) => (
              <li className="chart-legend-item" key={title}>
                <span className="chart-legend-circle" style={{ background: `linear-gradient(${linearBg})` }} />
                <p>{title}</p>
              </li>
            ))}
          </ul>
        </Col>
        <Col lg={4}>
          <div className="token-info-container">
            <div className="token-info">
              <p>Total Token Supply</p>
              <p>{tokenSupply}</p>
            </div>
            <div className="token-info">
              <p>Token Ticker</p>
              <p><b>{tokenTicker}</b></p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12} className="mx-auto text-center mt-5 d-none">
          <h2 className="text-muted token-footer-text">{rootContent}</h2>
        </Col>
      </Row>
    </PageSection>
  );
};

Token.propTypes = {
  frontmatter: PropTypes.object,
};

Token.defaultProps = {
  frontmatter: null,
};

export default Token;
