import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";
import clsx from "clsx"


const AppleIcon = ({ imageFileName, className }) => {
  const imgPart = (
    <Image className={clsx("img-fluid d-block apple-icon", className)} fileName={imageFileName} alt={imageFileName} />
  );

  return (
      <a href="https://apps.apple.com/tt/app/spacelens-shop-sell-swap/id1456779132" target="_blank" rel="noopener noreferrer">
        {imgPart}
      </a>
    );

};

AppleIcon.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  className: PropTypes.string
};

AppleIcon.defaultProps = {
  className: ""
}


export default AppleIcon;
