import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Button, Form } from "react-bootstrap";
import PageSection from "components/PageSection";
import clsx from "clsx";
import Image from "components/Image";
import AppleIcon from "components/AppleIcon"
import background from "../../../images/background/contact_bg.jpg";



import './Contact.scss';

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, imageFileName } = frontmatter;

  return (
    <PageSection className={clsx("contact-section py-5", className)} id={anchor} style={{ backgroundImage: `url(${background})` }}>
      <Row className="justify-content-center mb-0 mb-md-5">
        <Col lg={4} className="text-center">
          <h2 className="mb-4 mb-md-5">Download <span className="primary-color"><b>Spacelens</b></span> Now</h2>
          <h4 className="mb-4 mb-md-5">Start offering products &amp; goods on the Spacelens platform and create your unique Spacelens wallet.</h4>
          <AppleIcon className="mt-0 mt-mb-5 apple-icon mx-auto" imageFileName={imageFileName} /> 
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
