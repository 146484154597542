import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Client from "components/Client";
import './Clients.scss';

const Clients = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, clients, heading } = frontmatter;

  return (
    <PageSection className={clsx("py-5 clients-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={heading} className="mb-2 mb-md-5" />
      </Row>
      <Row md={4} sm={3} xs={3} className="clients-row">
        {clients.map(({ href, imageFileName, className:customClass }) => (
          <Col  className={customClass} key={imageFileName}>
            <Client href={href} imageFileName={imageFileName} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Clients.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Clients.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Clients;
