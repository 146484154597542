import React from "react";
import PropTypes from "prop-types";

import { Nav } from "react-bootstrap";
import { Link } from "react-scroll";
import FacebookIcon from "../images/svg/facebookFBrands.svg";
import RedditIcon from "../images/svg/redditBrands.svg";
import TwitterIcon from "../images/svg/twitterBrands.svg";
import YoutubeIcon from "../images/svg/youtubeBrands.svg";
import DiscordIcon from "../images/svg/discordBrands.svg";
import TelegramIcon from "../images/svg/telegram.svg";

import "./NavItemIcon.scss";

const NavItemIcon = ({ to, onClick, iconName }) => {
  return (
    <Nav.Item>
      <a className="nav-link cursor-pointer nav-link-icon" href={to}>
        {iconName === "Facebook" && <FacebookIcon />}
        {iconName === "Reddit" && <RedditIcon />}
        {iconName === "Youtube" && <YoutubeIcon />}
        {iconName === "Discord" && <DiscordIcon />}
        {iconName === "Twitter" && <TwitterIcon />}
        {iconName === "Telegram" && <TelegramIcon />}
      </a>
    </Nav.Item>
  );
};

NavItemIcon.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
};

NavItemIcon.defaultProps = {
  to: "",
  onClick: null,
  iconName: null,
};

export default NavItemIcon;
