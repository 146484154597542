import React from "react";
import PropTypes from "prop-types";
import Image from "components/Image";
import clsx from "clsx";
import "./ServiceItem.scss";

const ServiceItem = ({ iconName,  header, content, className, inverted }) => {
  let imagePart;
  if (iconName) {
    imagePart = <Image className="service-item-image" fileName={iconName} />;
  }

  const liClassName = clsx("service-item ", { "service-inverted": inverted });

  return (
    <div className={liClassName}>
      {inverted && imagePart }
      <p>{content}</p>
      
      {!inverted && imagePart }
      
    </div>
  );
};

ServiceItem.propTypes = {
  iconName: PropTypes.string,

  header: PropTypes.string,
  content: PropTypes.string,
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

ServiceItem.defaultProps = {
  iconName: null,
  header: "",
  content: "",
  className: "",
  inverted: false,
};

export default ServiceItem;
