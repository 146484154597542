import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Container, Card } from "react-bootstrap";
import Image from "components/Image";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";
import clsx from "clsx";
import "./Top.scss";

const Top = ({ frontmatter, isRestricted }) => {
  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "https://files.coinmarketcap.com/static/widget/currency.js";
    document.body.appendChild(scriptTag);
  }, []);

  if (!frontmatter) {
    return null;
  }

  const { header, subheader, imageFileName, jumpToAnchor, jumpToAnchorText } = frontmatter;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const scrollToSection = useSmoothScrollTo(jumpToAnchor);

  let extraInfoPart;
  if (jumpToAnchor && jumpToAnchorText) {
    extraInfoPart = (
      <a href="https://info.uniswap.org/#/pools/0xa462c95042fc3aa96aedc89ca4daadb7ebd366d8">
        <Button variant="primary">
          <span>{jumpToAnchorText}</span>
        </Button>
      </a>
    );
  }

  return (
    <div>
      {isRestricted && (
        <section>
          <div
            className="alert alert-danger text-center"
            role="alert"
            style={{ marginBottom: 0, borderRadius: 0 }}
          >
            <b style={{ fontSize: "1.3rem", display: "block", marginBottom: "10px" }}>
              Spacelens is not available{" "}
              <span className="break-on-mobile">in your region yet!</span>
            </b>
            Spacelens doesn’t allow access or use of our Services if you are currently located,
            incorporated or otherwise established in, or a citizen or resident of the United States
            or any “Restricted Jurisdiction” (as that term is defined in) our{" "}
            <a href="https://www.spacelens.com/legal/terms-conditions">Terms & Conditions</a>.{" "}
            <span className="break-on-mobile">
              You can read more about it{" "}
              <a href="https://blog.spacelens.com/why-are-we-restricting-certain-countries-and-individuals">
                here
              </a>
              .
            </span>
          </div>
        </section>
      )}
      <Card className={clsx("image-card bg-dark text-white top-section")}>
        <Image className="image bg-image" fileName={imageFileName} alt={header || subheader} />
        <Card.ImgOverlay className="no-padding ">
          <Container>
            <div className="intro-text">
              <h2 className="intro-heading">
                <span className="medium-font">Decentralized</span>{" "}
                <span className="primary-color">
                  <b>eCommerce</b>
                </span>
              </h2>
              <div className="intro-divider-container">
                <hr className="intro-divider" />
              </div>
              <div className="intro-lead-in">
                <p>Spacelens offers a modern eCommerce platform owned by its community.</p>
                <p>
                  <span className="primary-color">SPACE</span> is a decentralized marketplace token.
                </p>
              </div>
              <div className="intro-mobile-accent d-md-none">
                <Image
                  className="image intro-mobile-accent-image"
                  fileName="icons/top_section_icon.png"
                  alt={header || subheader}
                />
              </div>
              {extraInfoPart}
              <div
                className="coinmarketcap-currency-widget"
                data-currencyid="11026"
                data-base="USD"
                data-ticker="true"
                data-rank="false"
                data-marketcap="false"
                data-volume="false"
                data-statsticker="true"
                data-stats="USD"
              />
            </div>
          </Container>
          <div className="extra-assets">
            <div className="extra-image-container">
              <Image
                className="image"
                fileName="icons/top_section_icon.png"
                alt={header || subheader}
              />
            </div>
            <hr className="extra-divider" />
          </div>
        </Card.ImgOverlay>
      </Card>
    </div>
  );
};

Top.propTypes = {
  frontmatter: PropTypes.object,
  isRestricted: PropTypes.bool,
};

Top.defaultProps = {
  frontmatter: null,
  isRestricted: false,
};

export default Top;
