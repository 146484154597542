import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Navbar from "views/Navbar";
import Top from "views/Top";
import Footer from "views/Footer";
import * as Sections from "views/Sections";
import SEO from "components/SEO";
import LanguageSelector from "components/LanguageSelector";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import fileNameToSectionName from "utils/fileNameToSectionName";
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";

import "../style/main.scss";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query IndexQuery($langKey: String!) {
    site {
      siteMetadata {
        title
        keywords
        description
      }
    }
    allMarkdownRemark(
      filter: { fields: { langKey: { eq: $langKey } } }
      sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }
    ) {
      nodes {
        frontmatter {
          brand
          anchor
          socials {
            name
            link
          }
          heading
          clients {
            href
            imageFileName
          }
          content
          copyright
          header
          imageFileName
          imageFileName2
          mobileImageFileName
          arrowImageFileName
          jumpToAnchor
          jumpToAnchorText
          menuText
          services1 {
            content
            header
            iconName
          }
          services2 {
            content
            header
            iconName
          }
          social {
            facebook
            github
            linkedin
            medium
            twitter
          }
          footerLogoFileName
          footerAddress
          navigationLinks {
            title
            link
            icon
          }
          subheader
          teamMember {
            header
            imageFileName
            social {
              facebook
              github
              linkedin
              medium
              twitter
            }
            subheader
          }
          title
          rootContent
          appleIcon
          spacelensLogo
          roadmap {
            title
            content
            icon
            filled
          }
          tokenSupply
          tokenTicker
          tokenChartLegend {
            title
            background
          }
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({ data, pageContext: { langKey, defaultLang, langTextMap } }) => {
  const [isRestrictedCountry, setIsRestrictedCountry] = useState(false);

  useEffect(() => {
    const url = "https://api.db-ip.com/v2/pbee03594167f6fbf9ad3848b3c01f8ef14f7fd5/self";
    // const url2 = "http://api.db-ip.com/v2/free/self";
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const countryCode = result.countryCode;
        if (
          countryCode === "US" ||
          countryCode === "IR" ||
          countryCode === "KP" ||
          countryCode === "CU" ||
          countryCode === "SD" ||
          countryCode === "SY"
        ) {
          setIsRestrictedCountry(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // window.location.replace("https://www.spacelens.com/");
  }, []);

  const {
    site: {
      siteMetadata: { title, keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } = breakDownAllNodes(nodes);

  let langSelectorPart;
  if (langTextMap != null && Object.keys(langTextMap).length > 1) {
    langSelectorPart = (
      <LanguageSelector langKey={langKey} defaultLang={defaultLang} langTextMap={langTextMap} />
    );
  }

  const style = {
    background: "black",
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  /*
  return (
    <div style={style}>
      <PropagateLoader color="#ffffff" size="15" />
    </div>
  );

  */

  return (
    <>
      <SEO lang={langKey} title={title} keywords={keywords} description={description} />
      <Navbar
        anchors={anchors}
        frontmatter={navBarNode.frontmatter}
        extraItems={langSelectorPart}
        isRestricted={isRestrictedCountry}
      />
      <Top frontmatter={topNode.frontmatter} isRestricted={isRestrictedCountry} />
      {
        // dynamically import sections
        sectionsNodes.map(({ frontmatter, fields: { fileName } }, ind) => {
          const sectionComponentName = fileNameToSectionName(fileName);
          const SectionComponent = Sections[sectionComponentName];

          return SectionComponent ? (
            <SectionComponent key={sectionComponentName} frontmatter={frontmatter} />
          ) : null;
        })
      }
      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

IndexPage.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default IndexPage;
