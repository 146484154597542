import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import Image from "components/Image";
import background from "../../../images/background/features_bg.jpg";

import "./Features.scss";

const SectionHeader = ({mobileImageFileName}) => {
  return (
    <Col lg={12} sm={8} xs={10} className={clsx("section-header", "text-center")}>
      <Image className="mobile-image-accent" fileName={mobileImageFileName} />
      <h3 className="text-muted">Take the control back</h3>
      <h2 className="section-heading">A new way to shop in your <span className="primary-color"><b>SPACE</b></span></h2>
    </Col>
  );
};

const FeatureContent = () => {
  return (
    <div className="feature-items-container">
      <div className="feature-line-background" />
      <div className="feature-item">
        <div className="feature-circle" />
        <p>Create listings on <br/>the blockchain</p>
      </div>
      <div className="feature-item">
        <div className="feature-circle" />
        <p>Own and really control <br/>your store</p>
      </div>
      <div className="feature-item">
        <div className="feature-circle" />
        <p>Verified and <br/>unalterable reviews</p>
      </div>
      <div className="feature-item">
        <div className="feature-circle" />
        <p>Shop with more<br/>confidence</p>
      </div>
      <div className="feature-item">
        <div className="feature-circle" />
        <p>Commerce with<br/>privacy</p>
      </div>
    </div>
  );
};

const SectionFooter = () => {
  return (
    <Col lg={12} className={clsx("section-footer", "text-center", "mb-5")}>
      
      <h2 className="section-heading"><span className="green-color">Next generation</span> eCommerce.</h2>
    </Col>
  );
};

const Features = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, clients, heading, mobileImageFileName } = frontmatter;

  return (
    <PageSection className={clsx("py-5", className, "features-wrapper")} id={anchor} style={{ backgroundImage: `url(${background})` }} >
      <Row className="justify-content-center">
        <SectionHeader className="mb-5" mobileImageFileName={mobileImageFileName} />
      </Row>
      <Row>
        <FeatureContent/>
      </Row>
      <Row>
        <SectionFooter />
      </Row>
    </PageSection>
  );
};

Features.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};



Features.defaultProps = {
  className: null,
  frontmatter: null,
};

SectionHeader.propTypes = {
  mobileImageFileName: PropTypes.string,
}

SectionHeader.defaultProps = {
  mobileImageFileName: "",
}

export default Features;
