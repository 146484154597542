import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";
import RoadmapItem from "components/RoadmapItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import background from "../../../images/background/roadmap_bg.jpg"

import "./Roadmap.scss";

const Roadmap = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, roadmap, rootContent } = frontmatter;

  return (
    <PageSection className="roadmap-wrapper py-5" containerClassName="container-large" id={anchor} style={{ backgroundImage: `url(${background})` }}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} className="mb-5" />
      </Row>
      <Row>
        <Col lg={12}>
          <ul className="roadmap">
            {roadmap.map(({ title, content, filled, icon }, ind) => (
              <RoadmapItem
                title={title}
                content={content}
                filled={filled}
                icon={icon}
                key={title}
              />
            ))}
          </ul>
        </Col>
      </Row>

      <Row>
        <Col lg={6} className="mx-auto text-center mt-2 mt-md-5">
          <p className="large text-muted">{rootContent}</p>
        </Col>
      </Row>
    </PageSection>
  );
};

Roadmap.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Roadmap.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Roadmap;
