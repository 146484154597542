import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Button, Form } from "react-bootstrap";
import PageSection from "components/PageSection";
import clsx from "clsx";
import Image from "components/Image";


import './FeatureText.scss';

const FeatureText = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, imageFileName, imageFileName2 } = frontmatter;

  return (
    <PageSection className={clsx("feature-text-section py-5", className)} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={12} className="text-md-center">
          <h2 className="mb-4">Because now is the <span className="primary-color">time</span></h2>
          <p>The world of online shopping and eCommerce is dominated today by large organizations.</p>
          <p>They possess your data. Your tastes. Your affinities.</p>
          <p>They unilateraly block reputable sellers to offer their products.</p>
          <p>They impede consumers to have a wider range of choice.</p>
          <p>They control commerce and your consumption.</p>
          <hr className="feature-text-divider" />
          <h2 className="mb-4"><span className="primary-color">Decentralized</span> eCommerce</h2>
          <p>The world of online shopping and eCommerce is managed and governed by its users.</p>
          <p>You possess your data and privacy.</p>
          <p>Sellers control their listings and stores.</p>
          <p>Consumers can shop with privacy.</p>
          <p><span className="primary-color">You&apos;re not a product anymore.</span></p>
        </Col>
      </Row>
      <div className="feature-text-background">
        <Image className="background-icon icon-left" fileName={imageFileName} />
        <Image className="background-icon icon-right" fileName={imageFileName2} />
      </div>
    </PageSection>
  );
};

FeatureText.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

FeatureText.defaultProps = {
  className: null,
  frontmatter: null,
};

export default FeatureText;
