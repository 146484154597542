import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Client from "components/Client";
import background from "../../../images/background/platform-bg.jpg";

import "./Platforms.scss";

const Platforms = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, clients, heading } = frontmatter;

  return (
    <PageSection className={clsx("pt-5 pb-0 py-md-5", className, "platforms-section", "bg-dark", "text-light")} id={anchor} style={{ backgroundImage: `url(${background})` }}>
      <Row>
        <SectionHeader header={heading} className="mb-3 mb-md-5" />
      </Row>
      <Row className="align-items-center">
        {clients.map(({ href, imageFileName }) => (
          <Col md={4} sm={12} className="my-3" key={imageFileName}>
            <Client href={href} imageFileName={imageFileName} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Platforms.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Platforms.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Platforms;
