import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

import { Navbar, Container, Nav } from "react-bootstrap";

import useWindowOnScroll from "hooks/useWindowOnScroll";
import useSmoothScrollTo from "hooks/useSmoothScrollTo";
import Icon from "components/Icon";
import NavItem from "components/NavItem";
import NavItemIcon from "components/NavItemIcon";
import Logo from "../../images/logo/logo.png";

import "./Navbar.scss";

const MyNavbar = ({ anchors, frontmatter, extraItems, isRestricted }) => {
  const { brand, menuText, socials, navigationLinks } = frontmatter;

  const handleScrollToTop = useSmoothScrollTo(0);

  const [expanded, setExpanded] = React.useState(false);
  const toggleMenu = React.useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const closeMenu = React.useCallback(() => {
    setExpanded(false);
  }, []);
  const handleBrandClick = React.useCallback(() => {
    closeMenu();
    handleScrollToTop();
  }, [closeMenu, handleScrollToTop]);

  const [shrink, setShrink] = React.useState(false);
  const handleWindowScroll = React.useCallback(() => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setShrink(scrollTop > 100);
  }, []);
  useWindowOnScroll(handleWindowScroll);

  return (
    <Navbar className={clsx("navbar-root")} expand="lg" bg="dark" expanded={expanded}>
      <Container fluid>
        <Navbar.Brand className="cursor-pointer" onClick={handleBrandClick}>
          <img src={Logo} alt={brand} />
        </Navbar.Brand>
        {!isRestricted && (
          <Navbar.Toggle onClick={toggleMenu} aria-label="Toggle navigation">
            <Icon iconName="BarsIcon" />
          </Navbar.Toggle>
        )}
        {!isRestricted && (
          <Navbar.Collapse>
            <Nav className=" ml-auto">
              {navigationLinks.map(({ title, link, icon }) => (
                <NavItem key={title} to={link} title={title} icon={icon} onClick={closeMenu} />
              ))}
              <div className="navbar-icons-container">
                {socials.map((social) => (
                  <NavItemIcon
                    key={social.name}
                    to={social.link}
                    onClick={closeMenu}
                    iconName={social.name}
                  />
                ))}
              </div>
            </Nav>
            {extraItems}
          </Navbar.Collapse>
        )}
      </Container>
    </Navbar>
  );
};

MyNavbar.propTypes = {
  anchors: PropTypes.arrayOf(PropTypes.string),
  frontmatter: PropTypes.object,
  extraItems: PropTypes.any,
  isRestricted: PropTypes.bool,
};

MyNavbar.defaultProps = {
  anchors: [],
  frontmatter: {},
  extraItems: null,
  isRestricted: false,
};

export default MyNavbar;
