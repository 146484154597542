import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Image from "components/Image";

import "./RoadmapItem.scss";

const RoadmapItem = ({ title, content, filled, icon }) => {
  const headerPart = title ? <h4>{title}</h4> : null;

  const liClassName = clsx("roadmap-item col-md-4 col-sm-6", { "roadmap-filled": filled });

  return (
    <li className={liClassName}>
      <div className="roadmap-item-container">
        <div className="roadmap-heading mb-3">{headerPart}</div>
        <div className="roadmap-graphics mb-3 mb-md-5">
          <Image fileName={icon} className="roadmap-item-icon" />
        </div>
        <div className="roadmap-text">
          <p>{content}</p>
        </div>
      </div>
    </li>
  );
};

RoadmapItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  icon: PropTypes.string,
  filled: PropTypes.bool,
};

RoadmapItem.defaultProps = {
  title: "",
  content: "",
  icon: "",
  filled: false,
};

export default RoadmapItem;
